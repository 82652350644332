import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, type ControllerRenderProps, type FieldValues, type UseFormReturn } from "react-hook-form";
import type { ClientToolDef } from "shared/tool";
import { match, P } from "ts-pattern";
import { z } from "zod";
import { Button } from "./ui/button";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "./ui/form";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { mapValues } from "remeda";
import type React from "react";
import { Trash2, Plus } from "lucide-react";
import { MentionsInput, Mention } from 'react-mentions'
import { useCallback, useRef } from "react";
import { forwardRef } from "react";
import { ofetch } from "ofetch";
import type { Resource, UIResource } from "shared/data/resource";

const detectRef = (playgroundId?: string) => (o: unknown) => {
  if (typeof o !== "string") {
    return o;
  }
  if (!o.trim().startsWith(document.location.origin)) {
    return o;
  }
  const result = o.match(/playgrounds\/([^/]+)\/resources\/([^/]+)\/raw/);
  return match(result)
    .with([P._, P.when(x => x === playgroundId), P.select()], resourceId => ({
      $$resourceId: resourceId,
    }))
    .with([P._, P.string, P.string], ([_, playground, resourceId]) => ({
      $$resourceId: `${playground}/${resourceId}`,
    }))
    .otherwise(() => o);
};


type RequiredFieldProps = Partial<ControllerRenderProps<FieldValues, string>> & Pick<ControllerRenderProps<FieldValues, string>, "value" | "onChange">

const ResourceIdField: React.FC<{field: RequiredFieldProps}> = ({field}) => {
  return (
    <Input
      {...field}
      onPaste={e=> {
        const clipboardData = e.clipboardData.getData("text");
        const d = detectRef()(clipboardData)
        if (d !== clipboardData) {
        field.onChange(d);
        e.preventDefault();

        }
      }}
      value={field.value?.$$resourceId || ""}
      onChange={e => field.onChange({ $$resourceId: e.target.value })}
    />
  );
};
const FileUploadField: React.FC<{field: RequiredFieldProps}> = ({field}) => {
  return (
    <Input
      type="file"
      onChange={async (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
          const base64 = (reader.result as string).split(',')[1];
          field.onChange({
            $$file: base64,
            $$mimeType: file.type
          });
        };
        reader.readAsDataURL(file);
      }}
    />
  );
};


const ArrayField: React.FC<{
  fieldType: z.ZodArray<any>;
  field: RequiredFieldProps;
}> = ({ fieldType, field }) => {
  const values = field.value ?? [''];

  return (
    <div className="space-y-2">
      {values.map((_, index) => (
        <div key={index} className="flex gap-2">
          <div className="flex-1">
            <FormFieldInput 
              fieldType={fieldType.element}
              field={{
                value: values[index],
                onChange: val => {
                  const newValue = [...values];
                  newValue[index] = val;
                  field.onChange(newValue);
                }
              }}
            />
          </div>
          
          <Button
            type="button"
            variant="ghost"
            size="icon"
            onClick={() => {
              const newValue = values.filter((_, i) => i !== index);
              field.onChange(newValue.length ? newValue : ['']);
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      ))}
      <Button
        type="button"
        variant="outline"
        size="sm"
        className="flex gap-2"
        onClick={() => {
          field.onChange([...values, '']);
        }}
      >
        <Plus className="h-4 w-4" /> Add Item
      </Button>
    </div>
  );
};

const  CustomInput = forwardRef<HTMLTextAreaElement, React.ComponentProps<typeof Textarea>>((props, ref) => {
  return <Textarea   ref={ref} {...props} className="min-h-32"/>
})

async function getResourceSuggestions(query: string, team: string) {

  const response = await ofetch<{id: string, name: string, playground: string, generator_tool: string, text: string}[]>(`/api/search?team=${team}&query=${query}`)
  return response.map(x=> ({
    id: `${x.playground}/${x.id}`,
    display: x.name,
  }))

}

const TextAreaField: React.FC<RequiredFieldProps & {resolveResource: (resourceId: string)=> Promise<Resource>, onResourceSearch: (query: string)=> Promise<ResourceSearchResult[]>}> = ({ resolveResource, onResourceSearch, ...field }) => {
  return (
    <>
    <MentionsInput
      onPaste={(e)=> {
        const textArea = e.target as HTMLTextAreaElement;
        const isAllSelected = textArea.selectionStart === 0 && textArea.selectionEnd === textArea.value.length;
        if (!isAllSelected && textArea.value.trim() !== "") {
          return;
        } 
        e.preventDefault();
        const clipboardData = e.clipboardData.getData("text");
        const d = detectRef()(clipboardData)
        if (d !== clipboardData) {
          e.preventDefault();
          let resourceId = d["$$resourceId"]
          setTimeout(()=> {
            field.onChange(`[@@resource id='${resourceId}' description='${resourceId}' /]`);
          },0)
          resolveResource(resourceId).then(resource => {
            field.onChange(`[@@resource id='${resourceId}' description='${resource.friendlyName}' /]`);
          });
          return;
        }
        //#Brand Guidelines: Livecycle Insights (#MwGA4/tysxae)
        if (clipboardData.trim().split("\n").length === 1 && clipboardData.trim().startsWith("#")){
          let resourceId = clipboardData.trim().match(/\(#([^\s()<>]+)\)/s)?.[1];
          if (!resourceId){
            resourceId = clipboardData.trim().match(/#([^\s()<>]+)/s)?.[1];
          }
          if (resourceId){
            setTimeout(()=> {
              field.onChange(`[@@resource id='${resourceId}' description='${resourceId}' /]`);
            },0)
            resolveResource(resourceId).then(resource => {
              field.onChange(`[@@resource id='${resourceId}' description='${resource.friendlyName}' /]`);
            }).catch(e=> {
              field.onChange(`[@@resource id='${resourceId}' description='${resourceId} (not found)' /]`);
            });
            return;
          }
        }
        
      }}
      {...Mention.defaultProps}
      value={field.value || ""}
      a11ySuggestionsListLabel={"Suggested mentions"}
      suggestionsPortalHost={document.body}
      style={{
        highlighter:{
          padding: "9px 12px",
          lineHeight: "17px",
          pointerEvents: "none",
          zIndex: 1000,
        },
        minHeight: "96px",
        suggestions: {
          list: {
            zIndex: 1001,
            backgroundColor: 'black',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
          },
          item: {
            color: 'white',
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
              backgroundColor: 'orange',
            },
          },
        }
      }}
      inputComponent={CustomInput}
      onChange={(event, newValue, newPlainTextValue, mentions) => {
        field.onChange(newValue);
      }}
    >
      <Mention
        {...Mention.defaultProps}
        style={{
          zIndex: 1000,
          cursor: "pointer",
          pointerEvents: "all",
          color: "orange",
          backgroundColor: "black",
        }}
        markup="[@@resource id='__id__' description='__display__' /]"
        displayTransform={(x,display)=> display}        
        trigger="#"
        data={(query,callback)=> {  
          onResourceSearch(query).then(callback)
        }}
      />
    </MentionsInput>
    </>
  )
}
const FormFieldInput: React.FC<{fieldType: z.ZodType, field: RequiredFieldProps, resolveResource: (resourceId: string)=> Promise<Resource>, onResourceSearch: (query: string)=> Promise<ResourceSearchResult[]>}> = ({fieldType, field, resolveResource, onResourceSearch}) => {
  return match(fieldType)
  .with(P.instanceOf(z.ZodBoolean), () => <Input type="checkbox" {...field} onChange={x => field.onChange(x.target.checked)} />)
  .with(P.instanceOf(z.ZodNumber), () => <Input type="number" {...field} onChange={x => field.onChange(x.target.valueAsNumber)} />)
  .with(P.when((x)=> x instanceof z.ZodObject && x.shape["$$resourceId"]), () => <ResourceIdField field={field} />)
  .with(P.when((x)=> x instanceof z.ZodObject && x.shape["$$file"]), () => <FileUploadField field={field} />)
  .with(P.instanceOf(z.ZodArray), (x) =>  <ArrayField fieldType={x} field={field} />)
  .otherwise(() => (
    <TextAreaField resolveResource={resolveResource} onResourceSearch={onResourceSearch} {...field} />
  ))
}

type ResourceSearchResult = {
  id: string;
  display: string;
}

export const ResourceFromToolForm = ({
  tool,
  playgroundId,
  team,
  onSubmit,
  onEditCancel,
  existingResource,
  onResourceSearch: onResourceSearch2,
  resolveResource: onResolveResource2,
}: {
  tool: ClientToolDef;
  playgroundId?: string;
  team: string;
  onSubmit: (input: unknown) => void;
  onEditCancel?: () => void;
  existingResource?: UIResource;
  onResourceSearch?: (query: string) => Promise<ResourceSearchResult[]>;
  resolveResource?: (resourceId: string) => Promise<Resource>;
}) => {
  const schema = tool.args;

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: existingResource?.generator?.args,
  });

  const onResourceSearch = onResourceSearch2 ?? useCallback(async (query: string)=> {
    return getResourceSuggestions(query, team);
  }, [team]);

  const resolveResource = onResolveResource2 ?? useCallback(async (resourceId: string)=> {
    const [playground,resource] = resourceId.includes("/") ? resourceId.split("/") : [playgroundId, resourceId];
    const resourceRes = await fetch(`/api/playgrounds/${playground}/resources/${resource}`);
    const resourceData = await resourceRes.json();
    return resourceData;
  }, [team, playgroundId]);

  const isEditing = !!existingResource && existingResource.status !== "draft";

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(
          s => {
            let refArgs = mapValues(s, detectRef(playgroundId));
            onSubmit(refArgs);
          },
          err => console.log(err),
        )}
        className="space-y-8"
      >
        {Object.entries(schema.shape).map(([key, value]) => {
          const fieldType = value.isOptional() ? value._def.innerType : value;
          return (
            <FormField
              key={key}
              control={form.control}
              name={key}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {value.description || key}
                    {!value.isOptional() && <span className="text-red-500 ml-1">*</span>}
                  </FormLabel>
                  <FormControl>
                    <FormFieldInput fieldType={fieldType} field={field} resolveResource={resolveResource} onResourceSearch={onResourceSearch} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          );
        })}
        <div className="flex gap-2 justify-start">
          {isEditing && onEditCancel && (
            <Button type="button" variant="outline" onClick={onEditCancel}>
              Cancel
            </Button>
          )}
          <Button type="submit">
            {isEditing ? 'Regenerate' : 'Generate'}
          </Button>
        </div>
      </form>
    </Form>
  );
};
