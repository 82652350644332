import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Check, X } from "lucide-react";
import { useState, useRef, useEffect } from "react";

export const EditableName = ({ 
  value, 
  onSave,
  className = ""
}: { 
  value: string, 
  onSave: (newValue: string) => Promise<void>,
  className?: string
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isEditing) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        handleCancel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  const handleSave = async () => {
    if (editValue.trim() === value) {
      setIsEditing(false);
      return;
    }
    await onSave(editValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditValue(value);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div ref={containerRef} className="flex items-center gap-2 relative group">
        <Input
          autoFocus
          className={`h-6 pe-16 ${className}`}
          value={editValue}
          onChange={e => setEditValue(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleSave();
            } else if (e.key === "Escape") {
              handleCancel();
            }
          }}
        />
        <div className="absolute right-1 flex gap-1">
          <Button
            size="icon"
            variant="ghost"
            className="h-5 w-5 hover:bg-green-500/20"
            onClick={handleSave}
          >
            <Check className="h-3 w-3 text-green-500" />
          </Button>
          <Button
            size="icon"
            variant="ghost"
            className="h-5 w-5 hover:bg-red-500/20"
            onClick={handleCancel}
          >
            <X className="h-3 w-3 text-red-500" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <h2 
      className={`${className} cursor-pointer hover:bg-accent/50 px-2 py-1 rounded-md transition-colors`}
      onClick={() => {
        setEditValue(value);
        setIsEditing(true);
      }}
    >
      {value}
    </h2>
  );
}; 